import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonials from '../components/testimonials'

const IndexPage = () => (
  <Layout pageClass="home-page">
    <SEO title="Home" />
    <section className="home-top">
        <div className="text-container">
            <div className="h2-header">
                <div className="header-line"></div>
                <h2>Your Business</h2>
                <div className="header-line"></div>
            </div>
            
            <h1>How does it look?</h1>

            <Link to="/contact" className="contact-btn">Contact Us NOW!</Link>
        </div>
    </section>

    <section className="about-eot">
        <div className="inner">

            <div className="text-container typography">
                <div className="header-line"></div>
                <h2>
                    <span>Improve Your Business NOW with..</span> 
                    <span className="blue">Experience on Tap</span>
                </h2>
                <p>Experience On Tap has expanded. In addition to Advisory services that we have historically provided we are now providing implementation services. Cloud-based software specifically for SMEs:</p>
                <p><h3>The Tidy product range:</h3>
                        <ul>
                            <li><a href="https://tidyinternational.com/solutions/tidystock" target="_blank" rel="noreferrer">Tidy Stock</a> - buying, selling, and managing inventory.</li>
                            <li><a href="https://tidyinternational.com/solutions/tidywork" target="_blank" rel="noreferrer">Tidy Work</a> - quoting and implementing jobs and projects.</li>
                            <li><a href="https://tidyinternational.com/solutions/tidybuild" target="_blank" rel="noreferrer">Tidy Build</a> - construction management.</li>
                             <li><a href="https://tidyinternational.com/solutions/tidyenterprise" target="_blank" rel="noreferrer">Tidy Enterprise</a> - stock and work plus ecommerce.</li>
                             <li><a href="https://www.tidycommerce.com/" target="_blank" rel="noreferrer">Tidy Commerce</a> - build your own website and sell online.</li>
                             <li><a href="https://www.tidyclinic.com/" target="_blank" rel="noreferrer">Tidy Clinic</a> - the ultimate inventory management for healthcare.</li>

                        </ul>
                </p>
                <p><Link to="https://www.youtube.com/watch?v=jCWA5ogzzI0" className="services" target="_blank">TidyStock Full Product Demo</Link>
                    <br /><Link to="https://www.youtube.com/watch?v=4YTYRR_cAcw" className="services" target="_blank">TidyWork Full Product Demo</Link></p>
            </div>
            <div className="image"></div>
        </div>
    </section>
    
    <section className="services">
        <div className="service-intro">
            <div className="inner">
                <div className="header-line"></div>
                <h2>How We Can <span className="blue">Help</span></h2>
                <p>We work with you to review your business and its practices providing you with an opportunity to stand back from its day-to-day workings and look at the bigger picture. We help you to move from working in your business to working on your business.</p>
                <p>Our focus is to target opportunities for growth, identify potential performance improvements, and to see if your company culture is working for you or against you.</p>
                <Link to="/how-we-help" className="services">Read more</Link>
            </div>
            
        </div>
        <div className="service-list">
            <div className="service service-coaching">
                <div className="icon">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 36">
                        <path id="Icon_awesome-chalkboard-teacher" data-name="Icon awesome-chalkboard-teacher" d="M14.625,24.75a1.609,1.609,0,0,0-.5.077,9.275,9.275,0,0,1-2.879.486,9.283,9.283,0,0,1-2.879-.486,1.6,1.6,0,0,0-.5-.077A7.876,7.876,0,0,0,0,32.669,3.366,3.366,0,0,0,3.375,36h15.75A3.366,3.366,0,0,0,22.5,32.669,7.876,7.876,0,0,0,14.625,24.75ZM11.25,22.5A6.75,6.75,0,1,0,4.5,15.75,6.75,6.75,0,0,0,11.25,22.5ZM41.625,0h-27A3.436,3.436,0,0,0,11.25,3.487V6.75A8.9,8.9,0,0,1,15.75,8V4.5H40.5V24.75H36v-4.5H27v4.5H21.639a8.961,8.961,0,0,1,2.791,4.5H41.625A3.436,3.436,0,0,0,45,25.763V3.487A3.436,3.436,0,0,0,41.625,0Z" transform="translate(0)"/>
                        </svg>
                    </div>
                </div>
                <span className="text">Business Coaching</span>
            </div>
            <div className="service service-mentoring">
                <div className="icon">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 35.997">
                        <path id="Icon_awesome-hands-helping" data-name="Icon awesome-hands-helping" d="M34.313,13.5H23.625v3.938a5.063,5.063,0,0,1-10.125,0V8.887L8.937,11.63A4.49,4.49,0,0,0,6.75,15.483v3.326L1.125,22.057A2.245,2.245,0,0,0,.3,25.13l5.625,9.745A2.251,2.251,0,0,0,9,35.7l7.27-4.2h9.6a4.5,4.5,0,0,0,4.5-4.5H31.5a2.248,2.248,0,0,0,2.25-2.25v-4.5h.563A1.683,1.683,0,0,0,36,18.563V15.188A1.683,1.683,0,0,0,34.313,13.5ZM44.7,10.87,39.073,1.125A2.251,2.251,0,0,0,36,.3L28.73,4.5H21.544a4.534,4.534,0,0,0-2.384.682L16.8,6.652A2.235,2.235,0,0,0,15.75,8.557v8.88a2.813,2.813,0,0,0,5.625,0V11.25H34.313a3.94,3.94,0,0,1,3.938,3.938v2l5.625-3.248A2.252,2.252,0,0,0,44.7,10.87Z" transform="translate(0.002 -0.002)"/>
                        </svg>
                    </div>
                </div>
                <span className="text">Mentoring</span>
            </div>
            <div className="service service-projects">
                <div className="icon">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.5 36">
                        <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0ZM22.5,18.844A.846.846,0,0,1,23.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,23.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,14.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,14.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,5.344,18H8.156A.846.846,0,0,1,9,18.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,5.344,27H8.156A.846.846,0,0,1,9,27.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z"/>
                        </svg>
                    </div>
                </div>
                <span className="text">Projects</span>
            </div>

            <div className="service service-plan">
                <div className="icon">
                   <div>
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 19.249 26.992">
                        <path id="Icon_metro-steps" data-name="Icon metro-steps" d="M12.452,3.856c-2.263,0-4.1,3.021-4.1,6.748a59.074,59.074,0,0,0,1.024,7.668c.4,2.66,1.376,4.864,3.073,4.864s2.834-1.47,2.834-4.156c0-.915-.752-2.372-.786-3.616-.061-2.243,1.5-3.138,1.5-5.212,0-3.727-1.29-6.3-3.553-6.3Zm11.055,7.712c-2.262,0-3.553,2.569-3.553,6.3,0,2.074,1.565,2.969,1.5,5.212-.033,1.244-.786,2.7-.786,3.616,0,2.687,1.137,4.156,2.834,4.156s2.672-2.2,3.073-4.864A59.074,59.074,0,0,0,27.6,18.316c0-3.727-1.834-6.748-4.1-6.748Z" transform="translate(-8.355 -3.856)"/>
                        </svg>
                   </div>
                </div>
                <span className="text">Business Plan</span>
            </div>
        </div>
       
    </section>
    <Testimonials />
  </Layout>
)

export default IndexPage
